import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🔬 Analysis`}</h1>
    <ul>
      <li parentName="ul">{`See: `}<a parentName="li" {...{
          "href": "/notebooks",
          "title": "notebooks"
        }}>{`notebooks`}</a>{``}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://gist.github.com/colinmegill/32489f979f76cc06113cec515d305445%22"
            }}>{`https://gist.github.com/colinmegill/32489f979f76cc06113cec515d305445"`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://kyso.io/colinmegill/wfpl-analysis-v03ipynb"
            }}>{`https://kyso.io/colinmegill/wfpl-analysis-v03ipynb`}</a></li>
          <li parentName="ul"></li>
        </ul>
      </li>
      <li parentName="ul">{`Summary statistics on entire conversation`}
        <ul parentName="li">
          <li parentName="ul">{`How many participants were there?`}</li>
          <li parentName="ul">{`How many comments were submitted?`}
            <ul parentName="li">
              <li parentName="ul">{`How many comments were moderated in?`}</li>
              <li parentName="ul">{`How many comments moderated out?`}</li>
            </ul>
          </li>
          <li parentName="ul">{`What was the shape of the matrix, ie., how many possible votes were there?`}</li>
          <li parentName="ul">{`How many votes were submitted in total?`}
            <ul parentName="li">
              <li parentName="ul">{`How many agrees?`}</li>
              <li parentName="ul">{`How many disagrees?`}</li>
              <li parentName="ul">{`How many passes?`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Percent sparse / no vote`}</li>
          <li parentName="ul">{`What was the distribution of total votes by participant?`}</li>
          <li parentName="ul">{`What was the distribution of total agrees by participant?`}</li>
          <li parentName="ul">{`What was the distribution of total disagrees by participant?`}</li>
          <li parentName="ul">{`What was the mean of participant votes?`}</li>
          <li parentName="ul">{`What was the `}<a parentName="li" {...{
              "href": "/variance",
              "title": "variance"
            }}>{`variance`}</a>{` of participant votes`}</li>
          <li parentName="ul">{`What was the distribution of `}<a parentName="li" {...{
              "href": "/variance",
              "title": "variance"
            }}>{`variance`}</a>{` per comment`}
            <ul parentName="li">
              <li parentName="ul">{`as beeswarm`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`PCA on entire conversation`}
        <ul parentName="li">
          <li parentName="ul">{`Color by highest `}<a parentName="li" {...{
              "href": "/variance",
              "title": "variance"
            }}>{`variance`}</a>{``}</li>
          <li parentName="ul">{`Color by vote counts`}</li>
        </ul>
      </li>
      <li parentName="ul">{`UMAP on entire conversation`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Deal-with-0s-in-effect-towards-UMAP-and-Leiden",
              "title": "Deal with 0s in effect towards UMAP & Leiden"
            }}>{`Deal with 0s in effect towards UMAP & Leiden`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`Leiden graph based community detection`}</li>
      <li parentName="ul">{`Facilitate running `}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{` on topical sub conversations`}
        <ul parentName="li">
          <li parentName="ul">{`Listing groups of comments identified as topical subconversations`}</li>
          <li parentName="ul">{`Heatmaps on sub conversations`}</li>
          <li parentName="ul">{`Discussing clusters found`}</li>
          <li parentName="ul">{`Heatmaps of comments by average vote on `}<a parentName="li" {...{
              "href": "/subconversation",
              "title": "subconversation"
            }}>{`subconversation`}</a>{` comments`}</li>
          <li parentName="ul">{`Show overall contentiousness of a topic, ie., summary statistics on a subconversation, ie., summary stats on a heatmap`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Heatmaps by total disagree by cluster`}</li>
      <li parentName="ul">{`Heatmaps by total agree by cluster`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Representativeness",
          "title": "Representativeness"
        }}>{`Representativeness`}</a>{` in notebook for use on `}<a parentName="li" {...{
          "href": "/subconversations",
          "title": "subconversations"
        }}>{`subconversations`}</a>{` using Leiden`}
        <ul parentName="li">
          <li parentName="ul">{`Ie., add `}<a parentName="li" {...{
              "href": "/Representative-Comments",
              "title": "Representative Comments"
            }}>{`Representative Comments`}</a>{` math to notebook`}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/group-informed-consensus",
          "title": "group informed consensus"
        }}>{`group informed consensus`}</a>{` in automated report`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/group-informed-consensus",
          "title": "group informed consensus"
        }}>{`group informed consensus`}</a>{` in notebook`}
        <ul parentName="li">
          <li parentName="ul">{`Given what we know about groups and controlling for subgroups, here are the statements we can truly say are `}<a parentName="li" {...{
              "href": "/consensus",
              "title": "consensus"
            }}>{`consensus`}</a>{` (because even 90% agree could have a very important minority viewpoint)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Tell stories over time`}
        <ul parentName="li">
          <li parentName="ul">{`Timeline with binned counts to see who participated late`}</li>
          <li parentName="ul">{`Use aggregate stats on metadata and overall agreeableness to tell a story about who com es in or does what when`}</li>
          <li parentName="ul">{`What percentage of people come back and when?`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Demographic info overlay`}</li>
      <li parentName="ul">{`Is one cluster more disagreeable than another?`}</li>
      <li parentName="ul">{`Multiple dimensions`}</li>
      <li parentName="ul">{`How do you do a Bayesian dimensionality reduction that would do what we did with jetpack but do it probabilistically`}</li>
      <li parentName="ul">{`We have the time series by participant. How well are we able to predict the next vote of the participant as the conversation proceeds given our model of existing clusters? Do we better understand people?`}</li>
      <li parentName="ul">{`Agreeableness metric:`}
        <ul parentName="li">
          <li parentName="ul">{`Ideally, probabilistic (Bayesian)`}</li>
          <li parentName="ul">{`Heuristic if need be`}</li>
          <li parentName="ul">{`What is the likelihood that someone agrees or disagrees with a given comment?`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Topic clustering`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      